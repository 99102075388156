import React from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router";
import { createStructuredSelector } from "reselect";
import { NavLink } from "react-router-dom";
import { appMenu } from "../../store/modules_mallsStore/mm.select";
import menuConfig from "../../router/MenuConfig";
import { useEffect } from "react";
import { addMenuConfig } from "../../store/menuSearch/menu.action";
import { HTTPClient } from "../../service";

const MenuList = (props) => {
  const { layoutProps, appMenu, getMenuItemActive } = props;
  const { companyId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  // const menuConfigState = useSelector(state => state.MenuSearch.menuConfig);
  const baseUrl = companyId
    ? `companies/${companyId}`
    : `companies/${HTTPClient.getMallId()}`;
  const menuConfigState = {
    items: [
      {
        title: "Details",
        key: "companies",
        root: true,
        icon: "flaticon2-bell-5",
        bullet: "dot",
        page: baseUrl,
        // dynamicPage: (val)=> "companues"
        submenu: [
          {
            title: "Overview",
            key: "accountDetails",
            root: true,
            icon: "flaticon2-bell-5",
            bullet: "dot",
            page: `${baseUrl}/account-details`,
            submenu: [],
          },
          {
            title: "Loyalty Details",
            key: "notificationCenter",
            bullet: "dot",
            page: `${baseUrl}/control-center/loyalty-control`,
          },
          {
            title: "SMS Details",
            key: "sms",
            bullet: "dot",
            page: `${baseUrl}/sms-settings`,
          },
          {
            title: "Technical Details ",
            key: "technical",
            bullet: "dot",
            page: `${baseUrl}/technical-details`,
          },
          {
            title: "Custom Events",
            key: "customEvents",
            bullet: "dot",
            page: `${baseUrl}/custom-events`,
          },
          // {
          //   title: "Add Center Director ",
          //   key: "technical",
          //   bullet: "dot",
          //   page: `${baseUrl}/center-director`,
          // },
        ],
      },
    ],
  };

  const openDropdown = (key) => {
    let urlKey = location.pathname.split("/");
    let locationKey = urlKey[1];
    let openModule = [];
    let indexKey = null;
    menuConfigState.items.forEach((child, index) => {
      if (child.submenu) {
        openModule = child.submenu.filter((ele) => ele.page === locationKey);
        if (openModule.length > 0) {
          indexKey = index;
          let nameKey = menuConfigState.items[indexKey].key;
          let parentClass = document.getElementsByTagName(`li`);
          if (parentClass !== null && parentClass.length > 0) {
            setTimeout(() => {
              for (let i = 0; i < 50; i++) {
                if (parentClass.item(i) !== null) {
                  if (parentClass.item(i).id === nameKey) {
                    // console.log(parentClass.item(i))
                    // parentClass.item(i).classList.add(`menu-item-active`);
                    // parentClass.item(i).classList.add(`menu-item-open`);
                    parentClass.item(i).className += `${true &&
                      "menu-item-active"} menu-item-open`;
                  }
                }
              }
            }, 1000);
          }
        }
      }
    });
  };
  useEffect(() => {
    dispatch(addMenuConfig(menuConfig));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (Object.keys(menuConfigState).length > 0) {
      openDropdown();
    }
  }, [menuConfigState]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {appMenu ? (
        <>
          {
            // <li
            //     key={'Dashboard'}
            //     className={`menu-item ${getMenuItemActive(
            //       `/overview`, false
            //     )}`}
            //     aria-haspopup="true"
            //   >
            //   <NavLink className="menu-link" to="/new/overview">
            //     <span className="svg-icon menu-icon">
            //       <i className='fas fa fa-home'></i>
            //     </span>
            //       <span className="menu-text">Dashboard</span>
            //   </NavLink>
            // </li>
          }

          {/*
              <li
                  key={'Old Dashboard'}
                  className={`menu-item ${getMenuItemActive(
                    `/new/overview`, false
                  )}`}
                  aria-haspopup="true"
                >
                <NavLink className="menu-link" to="/new/overview">
                  <span className="svg-icon menu-icon">
                    <i className='fas fa fa-home'></i>
                  </span>
                    <span className="menu-text">Old Dashboard</span>
                </NavLink>
              </li> */}
          {menuConfigState.items.map((child, index) => {
            return (
              <>
                {child.title && (
                  <>
                    {child.submenu.length > 0 && (
                      // {/* Bootstrap */}
                      // {/*begin::1 Level*/}
                      <li
                        id={child.key}
                        className={`menu-item menu-item-submenu`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                      >
                        <NavLink
                          className="menu-link menu-toggle"
                          to={`/${child?.page || "overview"}`}
                        >
                          <span className="svg-icon menu-icon">
                            <i className={`${child.icon}`}></i>
                          </span>
                          <span className="menu-text">{child.title}</span>
                          <i className="menu-arrow" />
                        </NavLink>
                        <div className="menu-submenu ">
                          <ul className="menu-subnav">
                            <li
                              className="menu-item  menu-item-parent"
                              aria-haspopup="true"
                            >
                              <span className="menu-link">
                                <span className="menu-text">{child.title}</span>
                              </span>
                            </li>
                            {/*begin::2 Level*/}
                            {child.submenu.map((item, index) => {
                              return (
                                <>
                                  {// item.title && appMenu[child.key].subMenu[item.key] && (
                                  item.title && (
                                    <li
                                      className={`menu-item ${getMenuItemActive(
                                        `/${item.page}`
                                      )}`}
                                      aria-haspopup="true"
                                    >
                                      <NavLink
                                        className="menu-link"
                                        to={`/${item.page}`}
                                      >
                                        <i className="menu-bullet menu-bullet-dot">
                                          <span />
                                        </i>
                                        <span className="menu-text">
                                          {item.title}
                                        </span>
                                      </NavLink>
                                    </li>
                                  )}
                                </>
                              );
                            })}
                            {/*end::2 Level*/}
                          </ul>
                        </div>
                      </li>
                    )
                    // {/*end::1 Level*/}
                    }
                    {child.submenu.length === 0 && (
                      // {/*begin::2 Level*/}
                      <li
                        className={`menu-item ${getMenuItemActive(
                          `${child.page}`,
                          false
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to={`/${child.page}`}>
                          <span className="svg-icon menu-icon">
                            <i className={`${child.icon}`}></i>
                          </span>
                          <span className="menu-text">{child.title}</span>
                        </NavLink>
                      </li>
                    )
                    // {/*end::2 Level*/}
                    }
                  </>
                )}
              </>
            );
          })}

          {/* <li
                  key={'handler'}
                  className={`menu-item ${getMenuItemActive(
                    `/handler`, false
                  )}`}
                  aria-haspopup="true"
                >
                <NavLink className="menu-link" to="/handler">
                  <span className="svg-icon menu-icon">
                    <i className='fas fa fa-mobile'></i>
                  </span>
                    <span className="menu-text">Handler App</span>
                </NavLink>
              </li> */}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  appMenu,
});
export default connect(mapStateToProps)(MenuList);
